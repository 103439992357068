<template>
  <div>
    <div class="page_title">
      <div class="title">
        <i class="el-icon-office-building"> </i>
        <span>酒店详情</span>
      </div>
    </div>
    <div class="page_info">
      <div class="page_title info_box">
        <div class="left">
          <img :src="$global.regexImg(infoObj.cover_pic)"
               alt="">
        </div>
        <div class="right">
          <div class="text">
            <div>{{infoObj.name}}</div>
            <div>
              <el-rate v-model="infoObj.star_num"
                       void-color='#fff'
                       disabled></el-rate>
            </div>
            <div>{{infoObj.city}}-{{infoObj.address}}</div>
          </div>
          <div class="address">
            <p @click="onMerchant(infoObj.phone)"><img src="@/assets/img/meeting/icon_23.png"
                   alt=""><span>电话</span></p>
            <!-- <p><img src="@/assets/img/meeting/icon_24.png"
                   alt=""><span>地址</span></p> -->
          </div>

        </div>
      </div>
    </div>
    <div class="page_title">
      <div class="time_box">
        <p>
          <el-date-picker v-model="hotel.startTime"
                          @change="startChange"
                          value-format="yyyy-MM-dd"
                          type="date"
                          :picker-options='startDate'
                          :clearable='false'
                          format='yyyy年MM月dd日'
                          placeholder="选择开始日期">
          </el-date-picker>
        </p>
        <p class="day">{{hotel.day}}晚</p>
        <p>
          <el-date-picker v-model="hotel.endTime"
                          @change='endChange'
                          :picker-options='endDate'
                          value-format="yyyy-MM-dd"
                          :clearable='false'
                          type="date"
                          format='yyyy年MM月dd日'
                          placeholder="选择结束日期">
          </el-date-picker>
        </p>
      </div>
    </div>
    <div class="page_title"
         style='padding-bottom:40px'>
      <template v-if='list && list.length > 0'>
        <div class="room_row"
             v-for='(item,index) in list'
             @click='onLineBooking(item)'
             :key='index'>
          <div class="left">
            <img :src="$global.regexImg(item.cover)"
                 alt="">
            <p>{{item.name}}</p>
            <div>
              <span> {{item.bed_type}}</span>
              <span>{{item.wifi == 1 ?'有wifi' :'无wifi'}}</span>
              <span>{{item.window == 1 ?'有窗户' :'无有窗户'}}</span>
              <span>面积{{item.area}}m2</span>
            </div>
          </div>
          <div class="right">
            <p><span>¥</span>{{item.price}}</p>
            <div>
              <p class="textTop"
                 v-if="item.stock <= 3 && item.stock >0 "> 仅{{item.stock}}个</p>
              <div class="ButtonAnimation"
                   :class="item.stock ==0  ? 'statusD' :''">
                <p>{{item.stock > 0 ? '订':'满'}}</p>
                <p>在线订</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <tempData v-else />
    </div>
    <!-- room_detail_pop -->
    <el-dialog :visible.sync="isVisible"
               width="593px">
      <div class="detail_box">
        <div class="top_box">
          <!-- <img :src="$global.regexImg()"
               alt=""> -->
          <div class="left">
            <el-carousel class="swiper"
                         indicator-position='none'>
              <el-carousel-item v-for="(item,index) in  hotel.obj.pic_url"
                                class="swiper_row"
                                style=' width: 204px; height: 135px;'
                                :key='index'>
                <img :src="$global.regexImg(item)"
                     mode="">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="right">
            <div>{{hotel.obj.name}}</div>
            <div>
              <p><span>¥</span>{{hotel.obj.price}}<span>x{{hotel.day}}</span></p>
              <el-button class="button btn1"
                         v-if='hotel.obj.stock <= 0'>满房</el-button>
              <el-button @click="onReserve()"
                         v-else
                         class="button">在线预定</el-button>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="list">
            <p class="row">
              <img src="@/assets/img/meeting/hotel_01.png"
                   mode="">
              <span>{{hotel.obj.area}}m2</span>
            </p>
            <p class="row">
              <img src="@/assets/img/meeting/hotel_02.png"
                   mode="">
              <span>{{hotel.obj.prople_num}}人</span>
            </p>
            <p class="row">
              <img src="@/assets/img/meeting/hotel_03.png"
                   mode="">
              <span>{{hotel.obj.window == 2 ?'无窗' :'有窗'}}</span>
            </p>
            <p class="row">
              <img src="@/assets/img/meeting/hotel_06.png"
                   mode="">
              <span>{{hotel.obj.bed_type}}</span>
            </p>
            <p class="row">
              <img src="@/assets/img/meeting/hotel_04.png"
                   mode="">
              <span>{{hotel.obj.wifi == 2 ? '无' :'有'}}</span>
            </p>
            <p class="row">
              <img src="@/assets/img/meeting/hotel_05.png"
                   mode="">
              <span>{{hotel.obj.smoke == 2 ?'不可抽烟':'可抽烟'}}</span>
            </p>
            <p class="row">
              <img src="@/assets/img/meeting/hotel_07.png"
                   mode="">
              <span>{{hotel.obj.breakfast == 2 ?'无早餐' :'有早餐'}}</span>
            </p>
          </div>

        </div>
      </div>
    </el-dialog>
    <textarea style="opacity:0;width:1px;height:1px"
              id="input"
              key="textarea">复制</textarea>
  </div>
</template>

<script>
import tempData from '@/components/tempData'
import { get_hotel_detail } from '@/utils/Api/meeting'
export default {
  components: { tempData },
  props: [],
  data () {
    var startDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)}-${new Date().getDate() > 9 ? new Date().getDate() : '0' + new Date().getDate()}`
    return {
      swiperActive: 0,
      reserveLoading: false,
      startDate: {
        disabledDate: time => {
          let delay = startDate;
          if (delay) {
            return time.getTime() < new Date(delay).getTime() - 86400000;
          }
        }
      },
      endDate: {
        disabledDate: time => {
          let delay = this.hotel.startTime;
          if (delay) {
            return time.getTime() < new Date(delay).getTime();
          }
        }
      },
      infoObj: {
      },
      isVisible: false,
      hotel: {
        star_num: 0,
        startTime: startDate,
        address: '',
        endTime: null,
        timeList: [],
        id: null,
        day: null,
        obj: {
          pic_url: []
        },
      },
      list: [],
      meetingId: null,
      hotelId: null,
    }
  },
  methods: {
    onMerchant (x) {
      var input = document.getElementById('input')
      input.value = x
      input.select()
      document.execCommand('copy')
      this.$message.success('商家联系方式已复制！')
    },
    onLineBooking (row) {
      // if (row.stock <= 0) return
      this.hotel.id = row.id
      this.hotel.star_num = this.infoObj.star_num
      this.hotel.address = this.infoObj.address
      this.hotel.obj = JSON.parse(JSON.stringify(row))
      this.hotel.obj.title = this.infoObj.name
      this.isVisible = true
    },
    startChange (x) {
      this.getEndTime()
      this.getInfo()
    },
    endChange (x) {
      this.Computation()
    },
    getEndTime () {
      var data = new Date(parseInt(Date.parse(this.hotel.startTime) + 86400000)).toJSON().substr(0, 10).replace('T', ' ').replace(/\./g, '-');
      // this.endDate = data
      if (!this.hotel.endTime) {
        this.hotel.endTime = data
      }
      if (Date.parse(this.hotel.endTime) - Date.parse(data) < 0) this.hotel.endTime = data
      this.Computation()
    },
    Computation () {
      this.hotel.day = (Date.parse(this.hotel.endTime) - Date.parse(this.hotel.startTime)) / 86400000
    },
    onReserve () {
      this.isVisible = false
      this.$router.push({
        path: '/lineBooking',
        query: {
          hotelId: this.hotelId, meetingId: this.meetingId, info: encodeURIComponent(JSON.stringify(this.hotel)), cover: this.infoObj.cover_pic
        }
      })
    },
    async getInfo () {
      var data = await get_hotel_detail({
        date: this.hotel.startTime,
        hotel_id: this.hotelId
      })
      if (data) {
        this.list = data.room
        this.infoObj = data.hotel
      } else {
        this.$message.warning('酒店详情信息错误！')
      }
    }
  },
  created () {
    this.hotelId = this.$route.query.hotelId
    this.meetingId = this.$route.query.meetingId
    this.getInfo()
    this.getEndTime()
  },
  mounted () { },
  destroyed () { },
  computed: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
.page_title {
  width: 1200px;
  margin: 0 auto;
}
.pagination {
  margin: 20px 0;
}
.title {
  padding: 20px 0;
  text-align: left;
  color: #6c6c6c;
  line-height: 30px;
  span {
    margin-left: 10px;
  }
}
.page_info {
  background-color: #fff;
  width: 100%;
  .info_box {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    .left {
      width: 340px;
      height: 210px;
      overflow: hidden;
      img {
        width: 340px;
        height: 210px;
        &:hover {
          transform: scale(1.1);
          transition: all 0.3s;
        }
      }
    }
    .right {
      width: calc(100% - 400px);
      padding: 20px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .text {
        width: calc(100% - 210px);
        text-align: left;
        > div {
          text-align: left;
        }
        > div:nth-child(1) {
          font-size: 20px;
          line-height: 28px;
          color: #030303;
          margin-bottom: 20px;
        }
        > div:nth-child(3) {
          margin-top: 66px;
          font-size: 14px;
          line-height: 20px;
          color: #999999;
        }
      }
      .address {
        width: 210px;
        text-align: right;
        display: flex;
        align-items: center;
        p {
          cursor: pointer;
          display: inline-block;
          img {
            width: 25px;
            height: 25px;
            margin-right: 12px;
            vertical-align: middle;
          }
          span {
            font-size: 14px;
            color: #999;
          }
          &:last-child {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
.time_box {
  margin: 40px 0;
  width: 454px;
  height: 70px;
  opacity: 1;
  background: #fff;
  border-radius: 4px;
  border: 10px solid #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .day {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;
    height: 24px;
    line-height: 24px;
    background: #f4f4f4;
    border-radius: 12px;
    color: #999;
  }
  > p {
    flex: 1;
    display: flex;
    align-self: center;
    justify-content: center;
  }
  .el-date-editor.el-input,
  .el-date-editor /deep/ .el-input__inner {
    padding: 0;
    width: 100%;
    text-align: center;
    border: 0;
  }
  /deep/ .el-icon-date:before {
    content: '' !important;
  }
  .el-input__inner {
    border: 0;
    height: 70px;
    line-height: 70px;
  }
  .el-date-editor /deep/.el-range-separator {
    padding: 0 10px;
    height: 24px;
    max-width: 200px;
    color: #999999;
    border-radius: 16px;
    background-color: #f4f4f4;
  }
}
.room_row {
  cursor: pointer;
  &:hover {
    .left {
      > p {
        color: #47d7e3;
      }
    }
  }
  width: 1200px;
  height: 112px;
  background: #ffffff;
  border: 1px solid #d4effc;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .left {
    padding: 16px 0 16px 16px;
    box-sizing: border-box;
    width: calc(100% - 366px);
    display: flex;
    align-items: center;
    img {
      align-self: flex-start;
      width: 80px;
      height: 80px;
      border-radius: 10px;
    }
    > p {
      font-weight: 700;
      margin-left: 16px;
      width: 200px;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      display: block;
      overflow: hidden;
      text-overflow: ellispsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    > div {
      padding-left: 16px;
      box-sizing: border-box;
      width: calc(100% - 310px);
      font-size: 14px;
      line-height: 20px;
      color: #999;
      span {
        margin-right: 10px;
      }
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .right {
    width: 366px;
    display: flex;
    align-items: center;
    > p {
      text-align: center;
      height: 100%;
      line-height: 112px;
      border-left: 1px solid #e4f1f8;
      border-right: 1px solid #e4f1f8;
      width: 150px;
      box-sizing: border-box;
      font-size: 19px;
      color: #ff5151;
      font-size: 18px;
      font-weight: 700;
      span {
        font-size: 12px;
        font-weight: normal;
      }
    }
    > div {
      width: calc(100% - 150px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .textTop {
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translateX(-50%);
        color: #47d7e3;
        font-size: 14px;
      }
      > div {
        width: 95px;
        height: 62px;
        border: 1px solid #47d7e3;
        position: relative;
        overflow: hidden;
        font-size: 15px;
        > p:nth-child(1) {
          height: 50%;
          line-height: 31px;
          background-color: #47d7e3;
          color: #fff;
          text-align: center;
        }
        > p:nth-child(2) {
          height: 50%;
          line-height: 31px;
          color: #47d7e3;
          text-align: center;
        }
      }
    }
    .statusD {
      border: 1px solid #dddddd !important;
      > p:nth-child(1) {
        background: #dddddd !important;
        color: #fff !important;
      }
      p:nth-child(2) {
        color: #dddddd !important;
      }
    }
  }
}
.detail_box {
  padding: 30px 10px;
  .top_box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 33px;
    .right {
      width: calc(100% - 230px);
      padding: 15px 0;
      > div:nth-child(1) {
        text-align: left;
        font-size: 18px;
        line-height: 25px;
        color: #333;
        margin-bottom: 40px;
      }
      > div:nth-child(2) {
        display: flex;
        font-size: 19px;
        color: #ff5151;
        font-size: 18px;
        font-weight: 700;
        span {
          font-size: 12px;
          font-weight: normal;
        }
        span:nth-child(2) {
          font-weight: 700;
          font-size: 14px;
          color: #333;
          margin-left: 5px;
        }
      }
      .button {
        border: 0;
        margin-left: 35px;
        width: 112px;
        height: 40px;
        background: #47d7e3;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
      }
      .btn1 {
        background-color: #cccccc;
      }
    }
    .left {
      display: flex;
      justify-content: space-between;
      width: 204px;
      height: 135px;
      /deep/ .el-carousel__container {
        width: 204px;
        height: 135px;
      }
      .swiper,
      .swiper_row,
      img {
        width: 204px;
        height: 135px;
      }
    }
  }
}
.info {
  border-top: 1px solid #e0e0e0;
  .list {
    padding: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    .row {
      width: 40%;
      text-align: left;
      margin-bottom: 22px;
      img {
        vertical-align: middle;
        max-width: 16px;
        max-height: 16px;
        margin-right: 12px;
      }
      span {
        vertical-align: middle;
        font-size: 13px;
        line-height: 18px;
        color: #333;
      }
    }
  }
}
/deep/ .el-dialog__headerbtn {
  font-size: 26px;
}
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #333;
}
/deep/ .el-carousel__indicators {
  display: none;
}
</style>
